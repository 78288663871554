<template>
  <b-row>
    <form-wizard
      ref="refFormWizard"
      :title="null"
      :subtitle="null"
      :hide-buttons="hideButtons"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3 w-100"
      @on-complete="formSubmitted"
      @on-change="onTabChange"
    >
      <tab-content title="Session Details">
        <session-details-tab
          :stage.sync="formStage"
          :session.sync="sessionData"
          :courses.sync="coursesData"
          :course.sync="courseData"
        />
      </tab-content>

      <tab-content title="Course Details" v-if="stageCourseDetails || formStage === 1">
        <course-details-tab
          :stage.sync="formStage"
          :session.sync="sessionData"
          :subject.sync="subjectData"
          :courses.sync="coursesData"
          :course.sync="courseData"
        />
      </tab-content>

      <tab-content title="Class Details" v-if="stageClassCreator || formStage === 2">
        <class-creator-tab
          :stage.sync="formStage"
          :session.sync="sessionData"
          :courses.sync="coursesData"
          :course.sync="courseData"
        />
      </tab-content>

      <tab-content title="Summary" v-if="stageSummary || formStage === 3">
        <session-summary-tab
          :pathway.sync="pathwayData"
          :stage.sync="formStage"
          :session.sync="sessionData"
          :courses.sync="coursesData"
          :course.sync="courseData"
        />
      </tab-content>
    </form-wizard>
  </b-row>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { BRow, BCol, BCard, BCardBody, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import { required, email } from '@validations';
import SessionDetailsTab from '@/views/apps/roster/sessions-builder/tabs/SessionDetailsTab';
import CourseDetailsTab from '@/views/apps/roster/sessions-builder/tabs/CourseDetailsTab';
import { onUnmounted, ref } from '@vue/composition-api';
import SessionSummaryTab from '@/views/apps/roster/sessions-builder/tabs/SessionSummaryTab';
import ClassCreatorTab from '@/views/apps/roster/sessions-builder/tabs/ClassCreatorTab';
import store from '@/store';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';

export default {
  components: {
    ClassCreatorTab,
    SessionSummaryTab,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    //
    SessionDetailsTab,
    CourseDetailsTab,
  },
  data() {
    return {
      sessionData: { name: undefined, visibility: 'public', status: 'created' },
      coursesData: [],
      courseData: { name: undefined, subject: undefined, visibility: 'public', status: 'created' },
      pathwayData: {},
      subjectData: {},

      stageCourseDetails: false,
      stageClassCreator: false,
      stageSummary: false,

      hideButtons: true,

      formStage: 0,
    };
  },
  watch: {
    formStage() {
      if (this.formStage === 0) {
        this.stageCourseDetails = false;
        this.stageClassCreator = false;
        this.stageSummary = false;

        this.$nextTick(() => this.$refs.refFormWizard.changeTab(undefined, 0));
      } else if (this.formStage === 1) {
        this.stageCourseDetails = true;
        this.stageClassCreator = false;
        this.stageSummary = false;

        this.$nextTick(() => this.$refs.refFormWizard.changeTab(undefined, 1));
      } else if (this.formStage === 2) {
        this.stageClassCreator = true;

        this.$nextTick(() => this.$refs.refFormWizard.changeTab(undefined, 2));
      } else if (this.formStage === 3) {
        this.stageCourseDetails = false;
        this.stageClassCreator = false;
        this.stageSummary = true;

        this.$nextTick(() => this.$refs.refFormWizard.changeTab(undefined, 1));
      }
    },
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
  methods: {
    formSubmitted() {
      console.log(`stage >`, this.formStage);

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      });
    },
    onTabChange(previous, next) {
      if (previous) this.formStage = next;
    },

    confirmLeave() {
      return window.confirm('Are you sure you want to abandon your changes?');
    },

    beforeWindowUnload(e) {
      if (this.confirmLeave()) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
  },
  async mounted() {
    const { subject: subjectId, pathway: pathwayId } = this.$route.query;

    if (subjectId) {
      this.$store
        .dispatch('app-roster/fetchSubject', { id: subjectId })
        .then((response) => {
          const subject = response.data;

          console.log(`fetched subject`, subject);

          this.subjectData = subject;
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (pathwayId) {
      this.$store
        .dispatch('app-roster/fetchPathway', { id: pathwayId })
        .then((response) => {
          const pathway = response.data;

          console.log(`fetched pathway`, pathway);

          this.pathwayData = pathway;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
  setup(props) {
    const PROFILE_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROFILE_APP_STORE_MODULE_NAME, rosterStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROFILE_APP_STORE_MODULE_NAME);
      }
    });
  },
  metaInfo() {
    return {
      title: `Session Builder`,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
